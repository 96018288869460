import OpeningHours from '@marcel_quo/opening_hours'
import {
  differenceInMinutes,
  endOfDay,
  format,
  intlFormat,
  isDate,
  isPast,
  isToday,
  parseISO,
  startOfDay,
} from 'date-fns'

export const useOpeningHours = () => {
  const { $log } = useNuxtApp()
  const { t } = useI18n()

  const checkOpeningHours = (ohValueMayBeRef, itemMayBeRef) => {
    const item = unref(itemMayBeRef)
    const ohValue = unref(ohValueMayBeRef)
    let warning = []
    if (!ohValue) {
      return 'Value is empty'
    }
    if (ohValue.trim().length === 0) {
      return 'Value is empty'
    }
    const nominatimObject = getNominatimObject(item)
    try {
      const oh = new OpeningHours(ohValue.trim(), nominatimObject)
      warning = oh.getWarnings()
    } catch (e) {
      warning = e
    }
    return warning
  }

  const getChangeShort = (item, detailKey) => {
    const state = getOpeningHoursState(item, detailKey, 'getState')
    const nextChange = getOpeningHoursState(item, detailKey, 'getNextChange')
    if (state === null || state === undefined) {
      return null
    } else if (state === true) {
      if (isDate(nextChange)) {
        const minutes = differenceInMinutes(nextChange, new Date())
        if (minutes <= 60) {
          return t('openingHours.closesAtTime', {
            time: format(nextChange, 'p'),
          })
        } else {
          return null
        }
      }
      return t('openingHours.open')
    } else if (state === false) {
      if (isDate(nextChange)) {
        const minutes = differenceInMinutes(nextChange, new Date())
        if (minutes <= 60) {
          return t('openingHours.opensAtTime', {
            time: format(nextChange, 'p'),
          })
        } else {
          return null
        }
      }
    }
  }

  const getNominatimObject = (itemMayBeRef) => {
    const item = unref(itemMayBeRef)
    const geoEntityStore = useGeoEntityStore()
    const nominatimObject = {}
    if (!item) {
      return null
    }

    if (item.addresses.length) {
      const address = {}
      const country = item.addresses.find(
        (address) => address.type === 'country'
      )
      if (country) {
        // $log('composables:useOpeningHours:getNominatimObject:country', country)
        const countryEntity = geoEntityStore.getGeoEntityById(
          country.geoEntityId
        )
        // $log(
        //   'composables:useOpeningHours:getNominatimObject:countryEntity',
        //   countryEntity
        // )
        if (countryEntity !== undefined && countryEntity.iso31661Alpha2) {
          address.country_code = countryEntity.iso31661Alpha2.toLowerCase()
          if (item.lat !== null) {
            nominatimObject.lat = item.lat
          }
          if (item.lon !== null) {
            nominatimObject.lon = item.lon
          }
          nominatimObject.address = address
          return nominatimObject
          // mayby add state if more filigrane oh needed
        }
      }
    }
    return null
  }

  // TODO: Nominatim Address Lookup
  const getOpeningHoursState = (item, detailKey, ohFunction = 'getState') => {
    if (!item || !detailKey) {
      return null
    }
    if (!item.details) {
      return null
    }
    const detail = item.details.find((detail) => detail.key === detailKey)
    if (detail && detail.value) {
      if (item.permanentlyClosedAt !== null) {
        const dateClosed = parseISO(item.permanentlyClosedAt)
        if (isPast(dateClosed) || isToday(dateClosed)) {
          return false
        }
      }
      try {
        const nominatimObject = getNominatimObject(item)
        const oh = new OpeningHours(detail.value, nominatimObject)
        // $log('composables:useOpeningHours:getOpeningHoursState:oh', oh)
        switch (ohFunction) {
          case 'getState':
            return oh.getState()
          case 'getNextChange':
            return oh.getNextChange()
          default:
            return null
        }
      } catch (e) {
        $log('composables:useOpeningHours:getOpeningHoursState:error', e)
        return false
      }
    }
  }

  const getOpenClosedShort = (item, detailKey) => {
    const state = getOpeningHoursState(item, detailKey, 'getState')
    if (state === null || state === undefined) {
      return null
    } else if (state === true) {
      return t('openingHours.open')
    } else if (state === false) {
      return t('openingHours.closed')
    }
    return null
  }

  const getOpenClosedShortWithState = (item, detailKey) => {
    const state = getOpeningHoursState(item, detailKey, 'getState')
    if (state === null || state === undefined) {
      return null
    } else if (state === true) {
      return { state: 'open', text: t('openingHours.open') }
    } else if (state === false) {
      return { state: 'closed', text: t('openingHours.closed') }
    }
    return null
  }

  const getOpenClosedOrChangeShort = (item, detailKey) => {
    const state = getOpeningHoursState(item, detailKey, 'getState')
    const nextChange = getOpeningHoursState(item, detailKey, 'getNextChange')
    if (state === null || state === undefined) {
      return null
    } else if (state === true) {
      if (isDate(nextChange)) {
        const minutes = differenceInMinutes(nextChange, new Date())
        if (minutes <= 60) {
          return t('openingHours.openClosesAtTime', {
            time: format(nextChange, 'p'),
          })
        } else {
          return t('openingHours.open')
        }
      }
      return t('openingHours.open')
    } else if (state === false) {
      if (isDate(nextChange)) {
        const minutes = differenceInMinutes(nextChange, new Date())
        if (minutes <= 60) {
          return t('openingHours.closedOpensAtTime', {
            time: format(nextChange, 'p'),
          })
        } else {
          return t('openingHours.closed')
        }
      }
    }
  }

  const getOpeningHoursIntervals = (
    date,
    item,
    itemOpeningHours,
    closedText,
    localeMayBeRef
  ) => {
    const locale = localeMayBeRef
    if (!date || !itemOpeningHours || !item) {
      return 'n/a'
    }
    const nominatimObject = getNominatimObject(item)
    try {
      const oh = new OpeningHours(itemOpeningHours, nominatimObject)
      const from = startOfDay(date)
      const to = endOfDay(date)
      let ohText = ''
      let timeFrom
      let timeTo
      let comma
      const intervals = oh.getOpenIntervals(from, to)
      for (const i in intervals) {
        timeFrom = intlFormat(
          intervals[i][0],
          { hour: 'numeric', minute: 'numeric' },
          { locale }
        )
        timeTo = intlFormat(
          intervals[i][1],
          { hour: 'numeric', minute: 'numeric' },
          { locale }
        )
        if (i > 0) {
          comma = '\n'
        } else {
          comma = ''
        }
        ohText +=
          comma +
          timeFrom +
          ' - ' +
          timeTo +
          (intervals[i][3] ? ' (' + intervals[i][3] + ')' : '')
      }
      if (ohText.length > 0) {
        return ohText
      } else {
        const comment = oh.getComment(date)
        if (comment !== undefined) {
          return closedText + ' (' + comment + ')'
        } else {
          return closedText
        }
      }
    } catch (e) {
      $log('composables:useOpeningHours:getOpeningHoursIntervals:error', e)
      return 'n/a'
    }
  }

  return {
    checkOpeningHours,
    getChangeShort,
    getOpenClosedOrChangeShort,
    getOpenClosedShortWithState,
    getOpenClosedShort,
    getOpeningHoursIntervals,
    getOpeningHoursState,
  }
}
